/**
 * local storage interface for user preferences
 */
export default class UserPreferences {
  /**
   * Returns the saved availability period
   * @return {string|null}
   */
  static getAvailabilityPeriod() {
    return localStorage.getItem('availability-period') !== 'null' ?
      localStorage.getItem('availability-period') :
      null;
  }

  /**
   * Sets the saved availability period
   * @param {string} period
   */
  static setAvailabilityPeriod(period) {
    localStorage.setItem('availability-period', period);
  }

  /**
   * Gets the saved availability territory
   * @param {string|null}
   */
  static getAvailabilityTerritory() {
    return localStorage.getItem('availability-territory') !== 'null' ?
      localStorage.getItem('availability-territory') :
      null;
  }

  /**
   * Sets the saved availability territory
   * @param {string} territory
   */
  static setAvailabilityTerritory(territory) {
    localStorage.setItem('availability-territory', territory);
  }

  /**
   * Gets the saved availability distributor
   * @param {string|null}
   */
  static getAvailabilityDistributor() {
    return localStorage.getItem('availability-distributor') !== 'null' ?
      localStorage.getItem('availability-distributor') :
      null;
  }

  /**
   * Gets the default play period start and end date when creating new selection
   * @return {any|null}
   */
  static getSelectionGroupPlayPeriod() {
    return localStorage.getItem('airline-selection-periods') !== 'null' ?
        JSON.parse(localStorage.getItem('airline-selection-periods')) :
        null;
  }

  /**
   * Sets the default play period start and end date when creating new selections
   * @param {string} playPeriodStart
   * @param {string} playPeriodEnd
   */
  static setSelectionGroupPlayPeriod(playPeriodStart, playPeriodEnd) {
    let selectionGroupPlayPeriods = {};
    if (localStorage.getItem('airline-selection-periods') !== null) {
      selectionGroupPlayPeriods = JSON.parse(localStorage.getItem('airline-selection-periods'));
    }

    selectionGroupPlayPeriods['playPeriodStart'] = playPeriodStart;
    selectionGroupPlayPeriods['playPeriodEnd'] = playPeriodEnd;

    localStorage.setItem('airline-selection-periods', JSON.stringify(selectionGroupPlayPeriods));
  }

  /**
   * Sets the saved availability distributor
   * @param {string} distributor
   */
  static setAvailabilityDistributor(distributor) {
    localStorage.setItem('availability-distributor', distributor);
  }

  /**
   * Returns the saved selection period
   * @return {string|null}
   */
  static getSelectionPeriod() {
    return localStorage.getItem('selection-period') !== 'null' ?
      localStorage.getItem('selection-period') :
      null;
  }

  /**
   * Sets the saved selection period
   * @param {string} period
   */
  static setSelectionPeriod(period) {
    localStorage.setItem('selection-period', period);
  }

  /**
   * Returns the saved selected content types
   * @return {string|null}
   */
  static getSelectedContentTypes() {
    return localStorage.getItem('selected-content-types') !== 'null' ?
      JSON.parse(localStorage.getItem('selected-content-types')) :
      null;
  }

  /**
   * Sets the saved selected content types
   * @param {string} contentTypes
   */
  static setSelectedContentTypes(contentTypes) {
    localStorage.setItem('selected-content-types', JSON.stringify(contentTypes));
  }

  /**
   * Returns the saved content-type for selections
   * @return {array|null}
   */
  static getStatusSheetContentType() {
    return localStorage.getItem('selection-content-type') !== 'null' ?
      JSON.parse(localStorage.getItem('selection-content-type')) :
      null;
  }

  /**
   * Sets the saved content type array
   * @param {array} contentType
   */
  static setStatusSheetContentType(contentType) {
    localStorage.setItem('selection-content-type', JSON.stringify(contentType));
  }

  /**
   * Returns the saved airline for the selection status sheet
   * @return {string|null}
   */
  static getStatusSheetAirline() {
    return localStorage.getItem('status-sheet-airline') !== 'null' ?
      localStorage.getItem('status-sheet-airline') :
      null;
  }

  /**
   * Sets the saved airline for the selection status sheet
   * @param {string} airline
   */
  static setStatusSheetAirline(airline) {
    localStorage.setItem('status-sheet-airline', airline);
  }

  /**
   * Get the media config template id for a given airline and content type. Object should be structured as follows:
   * {
   *   airlineId: {
   *     contentTypeId: mediaConfigTemplateId,
   *     contentTypeId: mediaConfigTemplateId,
   *     ...
   *   }
   *   airlineId: {...},
   * }
   * @param {string} airline
   * @param {string} contentType
   * @return {string|null}
   */
  static getStatusSheetAirlineSystem(airline, contentType) {
    let airlineSystem = {};
    if (null !== localStorage.getItem('status-sheet-airline-system')) {
      airlineSystem = JSON.parse(localStorage.getItem('status-sheet-airline-system'));
    }

    if ('undefined' === typeof airlineSystem[airline] || 'undefined' === typeof airlineSystem[airline][contentType]) {
      return null;
    }

    return airlineSystem[airline][contentType];
  }

  /**
   * Set the status sheet airline system, so we can pre-select systems on a per airline basis based on a user's last
   * choice
   * @param {string} airline
   * @param {string} contentType
   * @param {string|null} system
   */
  static setStatusSheetAirlineSystem(airline, contentType, system = null) {
    let airlineSystem = {};
    if (localStorage.getItem('status-sheet-airline-system') !== null) {
      airlineSystem = JSON.parse(localStorage.getItem('status-sheet-airline-system'));
    }

    if ('undefined' === typeof airlineSystem[airline]) {
      airlineSystem[airline] = {};
    }

    airlineSystem[airline][contentType] = system;

    localStorage.setItem('status-sheet-airline-system', JSON.stringify(airlineSystem));
  }

  /**
   * Returns the saved media config template for Movie selections
   * @return {array|null}
   */
  static getStatusSheetMovieMediaConfigTemplate() {
    return localStorage.getItem('selection-movie-media-config-template') !== 'null' ?
      localStorage.getItem('selection-movie-media-config-template') :
      null;
  }

  /**
   * Sets the saved media config template for Movies
   * @param mediaConfigTemplate
   */
  static setStatusSheetMovieMediaConfigTemplate(mediaConfigTemplate) {
    localStorage.setItem('selection-movie-media-config-template', mediaConfigTemplate);
  }

  /**
   * Returns the saved media config template for Television selections
   * @return {array|null}
   */
  static getStatusSheetTelevisionMediaConfigTemplate() {
    return localStorage.getItem('selection-television-media-config-template') !== 'null' ?
      localStorage.getItem('selection-television-media-config-template') :
      null;
  }

  /**
   * Sets the saved media config template for Television shows
   * @param mediaConfigTemplate
   */
  static setStatusSheetTelevisionMediaConfigTemplate(mediaConfigTemplate) {
    localStorage.setItem('selection-television-media-config-template', mediaConfigTemplate);
  }

  /**
   * Returns the saved group states for selections
   * @return {array|null}
   */
  static getStatusSheetGroupStates() {
    return localStorage.getItem('selection-group-entity-states') !== 'null' ?
      JSON.parse(localStorage.getItem('selection-group-entity-states')) :
      null;
  }

  /**
   * Sets the saved group states
   * @param states
   */
  static setStatusSheetGroupStates(states) {
    localStorage.setItem('selection-group-entity-states', JSON.stringify(states));
  }

  /**
   * Gets the saved view style for the Availability list
   * @return {string|null}
   */
  static getAvailabilityViewStyle() {
    return localStorage.getItem('availability-view-style') !== 'null' ?
      localStorage.getItem('availability-view-style') :
      null;
  }

  /**
   * Sets the saved view style for the Availability list
   * @param {string} style
   */
  static setAvailabilityViewStyle(style) {
    localStorage.setItem('availability-view-style', style);
  }

  /**
   * Gets the saved due date for the order form
   * @return {string|null}
   */
  static getOrderDueDate() {
    return localStorage.getItem('order-due-date') !== 'null' ?
      localStorage.getItem('order-due-date') :
      null;
  }

  /**
   * Sets the saved due date for the order form
   * @param {string} dueDate
   */
  static setOrderDueDate(dueDate) {
    localStorage.setItem('order-due-date', dueDate);
  }

  /**
   * Gets the saved search string for the selection list
   * @return {string|null}
   */
  static getSelectionSearchString() {
    return localStorage.getItem('selection-search-string') !== 'null' ?
      localStorage.getItem('selection-search-string') :
      null;
  }

  /**
   * Sets the saved search string for the selection list
   * @param {string} searchString
   */
  static setSelectionSearchString(searchString) {
    localStorage.setItem('selection-search-string', searchString);
  }

  /**
   * Gets the saved search string for the selection list
   * @return {Object|null}
   */
  static getPostCreateOrderAction() {
    return localStorage.getItem('post-create-order-action') !== 'null' ?
      JSON.parse(localStorage.getItem('post-create-order-action')) :
      null;
  }

  /**
   * Sets the saved search string for the selection list
   * @param {Object|null} postCreateOrderAction
   */
  static setPostCreateOrderAction(postCreateOrderAction) {
    localStorage.setItem('post-create-order-action', JSON.stringify(postCreateOrderAction));
  }

  /**
   * Gets the saved boolean value to determine the visibility of episodes on the status sheet
   * @return {boolean|null}
   */
  static getStatusSheetEpisodeVisibility() {
    const value = localStorage.getItem('status-sheet-episode-visibility');

    if (value === null) {
      return null;
    }

    if (value.toLowerCase() === 'true') {
      return true;
    }

    return false;
  }

  /**
   * Sets the saved search string for the selection list
   * @param {boolean} showEpisodes
   */
  static setStatusSheetEpisodeVisibility(showEpisodes) {
    localStorage.setItem('status-sheet-episode-visibility', String(showEpisodes));
  }

  /**
   * Gets the saved flag to determine the visibility of the status sheet breakdown
   * @return {boolean|null}
   */
  static getStatusSheetBreakdownVisibility() {
    const value = localStorage.getItem('status-sheet-breakdown-visibility');

    if (value === null) {
      return null;
    }

    if (value.toLowerCase() === 'true') {
      return true;
    }

    return false;
  }

  /**
   * Sets the saved flag to determine the visibility of the status sheet breakdown
   * @param {string} showBreakdown
   */
  static setStatusSheetBreakdownVisibility(showBreakdown) {
    localStorage.setItem('status-sheet-breakdown-visibility', showBreakdown);
  }

  /**
   * Gets the saved License order list filters for content type
   * @return {string[]}
   */
  static getLicenseOrderListContentTypes() {
    return localStorage.getItem('license-order-list-content-types') !== 'null' ?
      JSON.parse(localStorage.getItem('license-order-list-content-types')) :
      null;
  }

  /**
   * Gets the saved License order list filters for content type
   * @param {string[]} licenseOrderListContentTypes
   */
  static setLicenseOrderListContentTypes(licenseOrderListContentTypes) {
    localStorage.setItem('license-order-list-content-types', JSON.stringify(licenseOrderListContentTypes));
  }

  /**
   * Gets the saved License order list filters for title
   * @return {string}
   */
  static getLicenseOrderListTitle() {
    return localStorage.getItem('license-order-list-title') !== 'null' ?
      localStorage.getItem('license-order-list-title') :
      null;
  }

  /**
   * Sets the saved License order list filters for title
   * @param {string} licenseOrderListTitle
   */
  static setLicenseOrderListTitle(licenseOrderListTitle) {
    localStorage.setItem('license-order-list-title', licenseOrderListTitle);
  }

  /**
   * Gets the saved License order list filters for distributor
   * @return {string}
   */
  static getLicenseOrderListDistributor() {
    return localStorage.getItem('license-order-list-distributor') !== 'null' ?
      localStorage.getItem('license-order-list-distributor') :
      null;
  }

  /**
   * Sets the saved License order list filters for distributor
   * @param {string} licenseOrderListDistributor
   */
  static setLicenseOrderListDistributor(licenseOrderListDistributor) {
    localStorage.setItem('license-order-list-distributor', licenseOrderListDistributor);
  }

  /**
   * Gets the saved License order list filters for airline
   * @return {string}
   */
  static getLicenseOrderListAirline() {
    return localStorage.getItem('license-order-list-airline') !== 'null' ?
      localStorage.getItem('license-order-list-airline') :
      null;
  }

  /**
   * Sets the saved License order list filters for airline
   * @param {string} licenseOrderListAirline
   */
  static setLicenseOrderListAirline(licenseOrderListAirline) {
    localStorage.setItem('license-order-list-airline', licenseOrderListAirline);
  }

  /**
   * Gets the saved boolean value to determine the visibility of episodes on the license order list
   * @return {boolean|null}
   */
  static getLicenseOrderListEpisodeVisibility() {
    const value = localStorage.getItem('license-order-list-episode-visibility');

    if (value === null) {
      return null;
    }

    if (value.toLowerCase() === 'true') {
      return true;
    }

    return false;
  }

  /**
   * Sets the saved episode flag for the license order list
   * @param {boolean} showEpisodes
   */
  static setLicenseOrderListEpisodeVisibility(showEpisodes) {
    localStorage.setItem('license-order-list-episode-visibility', String(showEpisodes));
  }

  /**
   * Gets the saved License order list filters for content type
   * @return {string[]}
   */
  static getMaterialOrderListContentTypes() {
    return localStorage.getItem('material-order-list-content-types') !== 'null' ?
      JSON.parse(localStorage.getItem('material-order-list-content-types')) :
      null;
  }

  /**
   * Gets the saved License order list filters for content type
   * @param {string[]} materialOrderListContentTypes
   */
  static setMaterialOrderListContentTypes(materialOrderListContentTypes) {
    localStorage.setItem('material-order-list-content-types', JSON.stringify(materialOrderListContentTypes));
  }

  /**
   * Gets the saved Material order list filters for title
   * @return {string}
   */
  static getMaterialOrderListTitle() {
    return localStorage.getItem('material-order-list-title') !== 'null' ?
      localStorage.getItem('material-order-list-title') :
      null;
  }

  /**
   * Sets the saved Material order list filters for title
   * @param {string} materialOrderListTitle
   */
  static setMaterialOrderListTitle(materialOrderListTitle) {
    localStorage.setItem('material-order-list-title', materialOrderListTitle);
  }

  /**
   * Gets the saved Material order list filters for distributor
   * @return {string}
   */
  static getMaterialOrderListDistributor() {
    return localStorage.getItem('material-order-list-distributor') !== 'null' ?
      localStorage.getItem('material-order-list-distributor') :
      null;
  }

  /**
   * Sets the saved Material order list filters for distributor
   * @param {string} materialOrderListDistributor
   */
  static setMaterialOrderListDistributor(materialOrderListDistributor) {
    localStorage.setItem('material-order-list-distributor', materialOrderListDistributor);
  }

  /**
   * Gets the saved Material order list filters for airline
   * @return {string}
   */
  static getMaterialOrderListAirline() {
    return localStorage.getItem('material-order-list-airline') !== 'null' ?
      localStorage.getItem('material-order-list-airline') :
      null;
  }

  /**
   * Sets the saved Material order list filters for airline
   * @param {string} materialOrderListAirline
   */
  static setMaterialOrderListAirline(materialOrderListAirline) {
    localStorage.setItem('material-order-list-airline', materialOrderListAirline);
  }

  /**
   * Gets the saved boolean value to determine the visibility of episodes on the license order list
   * @return {boolean|null}
   */
  static getMaterialOrderListEpisodeVisibility() {
    const value = localStorage.getItem('material-order-list-episode-visibility');

    if (value === null) {
      return null;
    }

    if (value.toLowerCase() === 'true') {
      return true;
    }

    return false;
  }

  /**
   * Sets the saved search string for the selection list
   * @param {boolean} showEpisodes
   */
  static setMaterialOrderListEpisodeVisibility(showEpisodes) {
    localStorage.setItem('material-order-list-episode-visibility', String(showEpisodes));
  }

  /**
   * Gets the saved value for the transient state filter
   * @return {string|null}
   */
  static getStatusSheetTransientState() {
    return localStorage.getItem('status-sheet-transient-state') !== 'null' ?
      localStorage.getItem('status-sheet-transient-state') :
      null;
  }

  /**
   * Sets the value for the transient state filter
   * @param {string|null} transientState
   */
  static setStatusSheetTransientState(transientState) {
    localStorage.setItem('status-sheet-transient-state', transientState);
  }

  /**
   * Gets the saved images due date for the order form
   * @return {string|null}
   */
  static getImagesDueDate() {
    return localStorage.getItem('images-due-date') !== 'null' ?
      localStorage.getItem('images-due-date') :
      null;
  }

  /**
   * Sets the saved images due date for the order form
   * @param {string} dueDate
   */
  static setImagesDueDate(dueDate) {
    localStorage.setItem('images-due-date', dueDate);
  }

  /**
   * Gets the preferred view mode for the Selection Categories page
   * @return {string|null}
   */
  static getSelectionCategoriesPreferredMode() {
    return localStorage.getItem('selection-categories-preferred-mode') !== 'null' ?
      localStorage.getItem('selection-categories-preferred-mode') : null;
  }

  /**
   * Sets the preferred view mode for the Selection Categories page
   * @param {string} selectionCategoriesPreferredGroupMode
   */
  static setSelectionCategoriesPreferredMode(selectionCategoriesPreferredGroupMode) {
    localStorage.setItem('selection-categories-preferred-mode', selectionCategoriesPreferredGroupMode);
  }

  /**
   * Gets the last selected period for the Selection Categories page
   * @return {string|null}
   */
  static getSelectionCategoriesPeriod() {
    return localStorage.getItem('selection-categories-period') !== 'null' ?
      localStorage.getItem('selection-categories-period') : null;
  }

  /**
   * Sets the selected period for the Selection Categories page
   * @param {string} selectionCategoriesPeriod
   */
  static setSelectionCategoriesPeriod(selectionCategoriesPeriod) {
    localStorage.setItem('selection-categories-period', selectionCategoriesPeriod);
  }

  /**
   * Gets the saved Airline Client Portal airline filter
   * @return {string|null}
   */
  static getAirlineClientPortalAirline() {
    return localStorage.getItem('airline-client-portal-airline') !== 'null' ?
        localStorage.getItem('airline-client-portal-airline') :
        null;
  }

  /**
   * Sets the Airline Client Portal airline filter
   * @param {string|null} airlineClientPortalAirline
   */
  static setAirlineClientPortalAirline(airlineClientPortalAirline) {
    localStorage.setItem('airline-client-portal-airline', airlineClientPortalAirline);
  }

  /**
   * Gets the saved Airline Client Portal content filter
   * @return {string|null}
   */
  static getAirlineClientPortalContentTypes() {
    return localStorage.getItem('airline-client-portal-content-types') !== 'null' ?
        JSON.parse(localStorage.getItem('airline-client-portal-content-types')) :
        null;
  }

  /**
   * Sets the Airline Client Portal content filter
   * @param {array|null} airlineClientPortalContentTypes
   */
  static setAirlineClientPortalContentTypes(airlineClientPortalContentTypes) {
    localStorage.setItem('airline-client-portal-content-types', JSON.stringify(airlineClientPortalContentTypes));
  }

  /**
   * Gets the saved Airline Client Portal genre filter
   * @return {string|null}
   */
  static getAirlineClientPortalGenre() {
    return localStorage.getItem('airline-client-portal-genre') !== 'null' ?
        localStorage.getItem('airline-client-portal-genre') :
        null;
  }

  /**
   * Sets the Airline Client Portal genre filter
   * @param {string|null} airlineClientPortalGenre
   */
  static setAirlineClientPortalGenre(airlineClientPortalGenre) {
    localStorage.setItem('airline-client-portal-genre', airlineClientPortalGenre);
  }

  /**
   * Sets the Airline Client Portal Group By option
   * @param {string|null} groupBy
   */
  static setAirlineClientPortalGroupBy(groupBy) {
    localStorage.setItem('airline-client-portal-group-by', groupBy);
  }

  /**
   * Gets the saved Airline Client Portal Group By option
   * @return {string|null}
   */
  static getAirlineClientPortalGroupBy() {
    return localStorage.getItem('airline-client-portal-group-by') !== 'null' ?
        localStorage.getItem('airline-client-portal-group-by') :
        null;
  }

  /**
   * Gets the saved Airline Client Portal category filter
   * @return {string|null}
   */
  static getAirlineClientPortalCategory() {
    return localStorage.getItem('airline-client-portal-category') !== 'null' ?
        localStorage.getItem('airline-client-portal-category') :
        null;
  }

  /**
   * Sets the Airline Client Portal category filter
   * @param {string|null} airlineClientPortalCategory
   */
  static setAirlineClientPortalCategory(airlineClientPortalCategory) {
    localStorage.setItem('airline-client-portal-category', airlineClientPortalCategory);
  }

  /**
   * Gets the saved Airline Client Portal sort filter
   * @return {array|object|null}
   */
  static getAirlineClientPortalSortBy() {
    return localStorage.getItem('airline-client-portal-sort') !== 'null' ?
      JSON.parse(localStorage.getItem('airline-client-portal-sort')) :
      null;
  }

  /**
   * Sets the Airline Client Portal category filter
   * @param {string|null} languageCode
   */
  static setAirlineClientPortalMetadataLanguageCode(languageCode) {
    localStorage.setItem('airline-client-portal-metadata-language-code', languageCode);
  }

  /**
   * Gets the saved Airline Client Portal sort filter
   * @return {string|null}
   */
  static getAirlineClientPortalMetadataLanguageCode() {
    return localStorage.getItem('airline-client-portal-metadata-language-code') !== 'null' ?
      localStorage.getItem('airline-client-portal-metadata-language-code') :
      null;
  }

  /**
   * Sets the Airline Client Portal sort filter
   * @param {string|null} airlineClientPortalSort
   */
  static setAirlineClientPortalSortBy(airlineClientPortalSort) {
    localStorage.setItem('airline-client-portal-sort', JSON.stringify(airlineClientPortalSort));
  }

  /**
   * Gets the saved Airline Client Portal state filter
   * @return {string|null}
   */
  static getAirlineClientPortalState() {
    return localStorage.getItem('airline-client-portal-state') !== 'null' ?
        localStorage.getItem('airline-client-portal-state') :
        null;
  }

  /**
   * Sets the Airline Client Portal state filter
   * @param {string|null} airlineClientPortalState
   */
  static setAirlineClientPortalState(airlineClientPortalState) {
    localStorage.setItem('airline-client-portal-state', airlineClientPortalState);
  }

  /**
   * Gets the saved Airline Client Portal period filter
   * @return {string|null}
   */
  static getAirlineClientPortalPeriod() {
    return localStorage.getItem('airline-client-portal-period') !== 'null' ?
        localStorage.getItem('airline-client-portal-period') :
        null;
  }

  /**
   * Sets the Airline Client Portal period filter
   * @param {string|null} airlineClientPortalPeriod
   */
  static setAirlineClientPortalPeriod(airlineClientPortalPeriod) {
    localStorage.setItem('airline-client-portal-period', airlineClientPortalPeriod);
  }

  /**
   * Gets the saved Airline Client Portal language filter
   * @return {string|null}
   */
  static getAirlineClientPortalLanguage() {
    return localStorage.getItem('airline-client-portal-language') !== 'null' ?
        localStorage.getItem('airline-client-portal-language') :
        null;
  }

  /**
   * Sets the Airline Client Portal language filter
   * @param {string|null} airlineClientPortalLanguage
   */
  static setAirlineClientPortalLanguage(airlineClientPortalLanguage) {
    localStorage.setItem('airline-client-portal-language', airlineClientPortalLanguage);
  }

  /**
   * Gets the saved Airline Client Portal Content Language filter
   * @return {string|null}
   */
  static getAirlineClientPortalOriginalLanguage() {
    return localStorage.getItem('airline-client-portal-original-language') !== 'null' ?
        localStorage.getItem('airline-client-portal-original-language') :
        null;
  }

  /**
   * Sets the Airline Client Portal Content Language filter
   * @param {string|null} airlineClientPortalOriginalLanguage
   */
  static setAirlineClientPortalOriginalLanguage(airlineClientPortalOriginalLanguage) {
    localStorage.setItem('airline-client-portal-original-language', airlineClientPortalOriginalLanguage);
  }

  /**
   * Sets the Airline Client Portal View Mode filter
   * @param {string|null} viewMode
   */
  static setAirlineClientPortalViewMode(viewMode) {
    localStorage.setItem('airline-client-portal-view-mode', viewMode);
  }

  /**
   * Gets the saved Airline Client Portal View Mode filter
   * @return {string|null}
   */
  static getAirlineClientPortalViewMode() {
    return localStorage.getItem('airline-client-portal-view-mode') !== 'null' ?
        localStorage.getItem('airline-client-portal-view-mode') :
        null;
  }

  /**
   * Gets the saved Movie list filters
   * @return {string[]}
   */
  static getMovieListFilters() {
    return localStorage.getItem('movie-list-filters') !== 'null' ?
        JSON.parse(localStorage.getItem('movie-list-filters')) :
        null;
  }

  /**
   * Sets the Movie list filters
   * @param {string[]} movieListFilters
   */
  static setMovieListFilters(movieListFilters) {
    localStorage.setItem('movie-list-filters', JSON.stringify(movieListFilters));
  }

  /**
   * Gets the saved Movie list page the User last looked at
   * @return {string|null}
   */
  static getMovieListPage() {
    return localStorage.getItem('movie-list-page') !== 'null' ?
        localStorage.getItem('movie-list-page') : null;
  }

  /**
   * Sets the page the User is currently looking at
   * @param {string|null} movieListPage
   */
  static setMovieListPage(movieListPage) {
    localStorage.setItem('movie-list-page', movieListPage);
  }

  /**
   * Gets the saved order of the Movie list order
   * @return {string|null}
   */
  static getMovieListOrder() {
    return localStorage.getItem('movie-list-order') !== 'null' ?
        JSON.parse(localStorage.getItem('movie-list-order')) :
        null;
  }

  /**
   * Sets the order of the Movie list order
   * @param {string|null} movieListOrder
   */
  static setMovieListOrder(movieListOrder) {
    localStorage.setItem('movie-list-order', JSON.stringify(movieListOrder));
  }

  /**
   * Gets the saved Television list filters
   * @return {string[]}
   */
  static getTelevisionListFilters() {
    return localStorage.getItem('television-list-filters') !== 'null' ?
        JSON.parse(localStorage.getItem('television-list-filters')) :
        null;
  }

  /**
   * Sets the Television list filters
   * @param {string[]} televisionListFilters
   */
  static setTelevisionListFilters(televisionListFilters) {
    localStorage.setItem('television-list-filters', JSON.stringify(televisionListFilters));
  }

  /**
   * Gets the saved Television list page the User last looked at
   * @return {string|null}
   */
  static getTelevisionListPage() {
    return localStorage.getItem('television-list-page') !== 'null' ?
        localStorage.getItem('television-list-page') : null;
  }

  /**
   * Sets the page the User is currently looking at
   * @param {string|null} televisionListPage
   */
  static setTelevisionListPage(televisionListPage) {
    localStorage.setItem('television-list-page', televisionListPage);
  }

  /**
   * Gets the saved order of the Television list
   * @return {string|null}
   */
  static getTelevisionListOrder() {
    return localStorage.getItem('television-list-order') !== 'null' ?
        JSON.parse(localStorage.getItem('television-list-order')) :
        null;
  }

  /**
   * Sets the Television list order
   * @param {string|null} televisionListOrder
   */
  static setTelevisionListOrder(televisionListOrder) {
    localStorage.setItem('television-list-order', JSON.stringify(televisionListOrder));
  }

  /**
   * Gets the saved Other Video Item list filters
   * @return {string[]}
   */
  static getOtherVideoItemListFilters() {
    return localStorage.getItem('other-video-item-list-filters') !== 'null' ?
        JSON.parse(localStorage.getItem('other-video-item-list-filters')) :
        null;
  }

  /**
   * Sets the Other Video Item list filters
   * @param {string[]} otherVideoItemListFilters
   */
  static setOtherVideoItemListFilters(otherVideoItemListFilters) {
    localStorage.setItem('other-video-item-list-filters', JSON.stringify(otherVideoItemListFilters));
  }

  /**
   * Gets the saved Other Video Item list page the User last looked at
   * @return {string|null}
   */
  static getOtherVideoItemListPage() {
    return localStorage.getItem('other-video-item-list-page') !== 'null' ?
        localStorage.getItem('other-video-item-list-page') : null;
  }

  /**
   * Sets the page the User is currently looking at
   * @param {string|null} otherVideoItemListPage
   */
  static setOtherVideoItemListPage(otherVideoItemListPage) {
    localStorage.setItem('movie-list-page', otherVideoItemListPage);
  }

  /**
   * Gets the saved order of the Other Video Item list order
   * @return {string|null}
   */
  static getOtherVideoItemListOrder() {
    return localStorage.getItem('other-video-item-list-order') !== 'null' ?
        JSON.parse(localStorage.getItem('other-video-item-list-order')) :
        null;
  }

  /**
   * Sets the order of the Other Video Item list order
   * @param {string|null} otherVideoItemListOrder
   */
  static setOtherVideoItemListOrder(otherVideoItemListOrder) {
    localStorage.setItem('other-video-item-list-order', JSON.stringify(otherVideoItemListOrder));
  }

  static getBackgroundPreference() {
    const value = localStorage.getItem('background-preference');

    if (value === null || value.toLowerCase() === 'null') {
      return null;
    }

    if (value.toLowerCase() === 'true') {
      return true;
    }

    return false;
  }

  static setBackgroundPreference(backgroundPreference) {
    localStorage.setItem('background-preference', backgroundPreference);
  }

  /**
   * Gets the saved Airline filter for the Selection Category page
   * @return {string|null}
   */
  static getSelectionCategoryAirline() {
    return localStorage.getItem('selection-category-airline') !== 'null' ?
        localStorage.getItem('selection-category-airline') : null;
  }

  /**
   * Sets the saved transient state filter for the Selection Category page
   * @param {string|null} airline
   */
  static setSelectionCategoryAirline(airline) {
    localStorage.setItem('selection-category-airline', airline);
  }

  /**
   * Gets the saved transient state filter for the Selection Category page
   * @return {string|null}
   */
  static getSelectionCategoryTransientState() {
    return localStorage.getItem('selection-category-transient-state') !== 'null' ?
      localStorage.getItem('selection-category-transient-state') : null;
  }

  /**
   * Sets the saved transient state filter for the Selection Category page
   * @param {string|null} state
   */
  static setSelectionCategoryTransientState(state) {
    localStorage.setItem('selection-category-transient-state', state);
  }

  /**
   * Gets the airline last selected for the Selection Metadata
   * @return {string|null}
   */
  static getSelectionMetadataAirline() {
    return localStorage.getItem('selection-metadata-airline') !== 'null' ?
      localStorage.getItem('selection-metadata-airline') : null;
  }

  /**
   * Sets the saved transient state filter for the Selection Category page
   * @param {string|null} airline
   */
  static setSelectionMetadataAirline(airline) {
    localStorage.setItem('selection-metadata-airline', airline);
  }

  /**
   * Gets the cycle last selected for the Selection Metadata
   * @return {string|null}
   */
  static getSelectionMetadataCycle() {
    return localStorage.getItem('selection-metadata-cycle') !== 'null' ?
      localStorage.getItem('selection-metadata-cycle') : null;
  }

  /**
   * Sets the saved transient state filter for the Selection Category page
   * @param {string|null} cycle
   */
  static setSelectionMetadataCycle(cycle) {
    localStorage.setItem('selection-metadata-cycle', cycle);
  }

  /**
   * The transient state (New, Held, etc.) filter for the Selection Metadata page
   * @return {string|null}
   */
  static getSelectionMetadataTransientState() {
    return localStorage.getItem('selection-metadata-transient-state') !== 'null' ?
        localStorage.getItem('selection-metadata-transient-state') : null;
  }

  /**
   * Sets transient state (New, Held, etc.) filter for the Selection Metadata page
   * @param {string|null} state
   */
  static setSelectionMetadataTransientState(state) {
    localStorage.setItem('selection-metadata-transient-state', state);
  }

  /**
   * Gets the list filters of any list based on the key provided
   * @param userPrefKey
   * @return {any|null}
   */
  static getListFilter(userPrefKey) {
    return localStorage.getItem(userPrefKey) !== 'null' ?
      JSON.parse(localStorage.getItem(userPrefKey)) :
      null;
  }

  /**
   * Sets the list filters of any list based on the key provided
   * @param userPrefKey
   * @param item
   */
  static setListFilter(userPrefKey, item) {
    localStorage.setItem(userPrefKey, JSON.stringify(item));
  }

  /**
   * Removes the list filters of any list based on the key provided
   * @param userPrefKey
   */
  static removeListFilter(userPrefKey) {
    localStorage.removeItem(userPrefKey);
  }

  /**
   * Gets the saved Other Audio Group list filters
   * @return {string[]}
   */
  static getOtherAudioGroupListFilters() {
    return localStorage.getItem('other-audio-group-list-filters') !== 'null' ?
        JSON.parse(localStorage.getItem('other-audio-group-list-filters')) :
        null;
  }

  /**
   * Sets the Other Audio Group list filters
   * @param {string[]} OtherAudioGroupListFilters
   */
  static setOtherAudioGroupListFilters(OtherAudioGroupListFilters) {
    localStorage.setItem('other-audio-group-list-filters', JSON.stringify(OtherAudioGroupListFilters));
  }

  /**
   * Gets the saved Other Audio Group list page the User last looked at
   * @return {string|null}
   */
  static getOtherAudioGroupListPage() {
    return localStorage.getItem('other-audio-group-list-page') !== 'null' ?
        localStorage.getItem('other-audio-group-list-page') : null;
  }

  /**
   * Sets the page the User is currently looking at
   * @param {string|null} OtherAudioGroupListPage
   */
  static setOtherAudioGroupListPage(OtherAudioGroupListPage) {
    localStorage.setItem('other-audio-group-list-page', OtherAudioGroupListPage);
  }

  /**
   * Gets the saved order of the Other Audio Group list order
   * @return {string|null}
   */
  static getOtherAudioGroupListOrder() {
    return localStorage.getItem('other-audio-group-list-order') !== 'null' ?
        JSON.parse(localStorage.getItem('other-audio-group-list-order')) :
        null;
  }

  /**
   * Sets the order of the Other Audio Group list order
   * @param {string|null} OtherAudioGroupListOrder
   */
  static setOtherAudioGroupListOrder(OtherAudioGroupListOrder) {
    localStorage.setItem('other-audio-group-list-order', JSON.stringify(OtherAudioGroupListOrder));
  }

  /**
   * Gets the saved Other Audio Item list filters
   * @return {string[]}
   */
  static getOtherAudioItemListFilters() {
    return localStorage.getItem('other-audio-item-list-filters') !== 'null' ?
        JSON.parse(localStorage.getItem('other-audio-item-list-filters')) :
        null;
  }

  /**
   * Sets the Other Audio Item list filters
   * @param {string[]} OtherAudioItemListFilters
   */
  static setOtherAudioItemListFilters(OtherAudioItemListFilters) {
    localStorage.setItem('other-audio-item-list-filters', JSON.stringify(OtherAudioItemListFilters));
  }

  /**
   * Gets the saved Other Audio Item list page the User last looked at
   * @return {string|null}
   */
  static getOtherAudioItemListPage() {
    return localStorage.getItem('other-audio-item-list-page') !== 'null' ?
        localStorage.getItem('other-audio-item-list-page') : null;
  }

  /**
   * Sets the page the User is currently looking at
   * @param {string|null} OtherAudioItemListPage
   */
  static setOtherAudioItemListPage(OtherAudioItemListPage) {
    localStorage.setItem('other-audio-item-list-page', OtherAudioItemListPage);
  }

  /**
   * Gets the saved order of the Other Audio Item list order
   * @return {string|null}
   */
  static getOtherAudioItemListOrder() {
    return localStorage.getItem('other-audio-item-list-order') !== 'null' ?
        JSON.parse(localStorage.getItem('other-audio-item-list-order')) :
        null;
  }

  /**
   * Sets the order of the Other Audio Item list order
   * @param {string|null} OtherAudioItemListOrder
   */
  static setOtherAudioItemListOrder(OtherAudioItemListOrder) {
    localStorage.setItem('other-audio-item-list-order', JSON.stringify(OtherAudioItemListOrder));
  }

  /**
   * Sets the saved list of airlines for creating selections
   * @param {Object|null} includedAirlines
   */
  static setCreateSelectionIncludedAirlines(includedAirlines) {
    localStorage.setItem('create-selection-included-airlines', JSON.stringify(includedAirlines));
  }

  /**
   * Gets the saved list of airlines for creating selections
   * @return {Object|null}
   */
  static getCreateSelectionIncludedAirlines() {
    return localStorage.getItem('create-selection-included-airlines') !== 'null' ?
      JSON.parse(localStorage.getItem('create-selection-included-airlines')) :
      null;
  }

  /**
   * Return the saved company login
   * @return {Object|null}
   */
  static getCompanyLogin() {
    return localStorage.getItem('company-login') !== 'null' ?
        JSON.parse(localStorage.getItem('company-login')) :
        null;
  }

  /**
   * Sets the saved company login
   * @param {Object|null} companyLogin
   */
  static setCompanyLogin(companyLogin) {
    localStorage.setItem('company-login', JSON.stringify(companyLogin));
  }

  /**
   * Removes the saved company login
   */
  static removeCompanyLogin() {
    localStorage.removeItem('company-login');
  }

  /**
   * @return {string|null}
   */
  static getStatusSheetCycles() {
    return localStorage.getItem('status-sheet-cycles') !== 'null' ?
        localStorage.getItem('status-sheet-cycles') :
        null;
  }

  /**
   * Sets the Cycle string for Selections
   * @param {string|null} cycles
   */
  static setStatusSheetCycles(cycles) {
    localStorage.setItem('status-sheet-cycles', cycles);
  }

  /**
   * @return {array|null}
   */
  static getStatusSheetCycleConfigs() {
    return null !== localStorage.getItem('status-sheet-cycle-configs') ?
        JSON.parse(localStorage.getItem('status-sheet-cycle-configs')) :
        null;
  }

  /**
   * @param {array|null} cycleConfigs
   */
  static setStatusSheetCycleConfigs(cycleConfigs) {
    localStorage.setItem('status-sheet-cycle-configs', JSON.stringify(cycleConfigs));
  }

  /**
   * @return {string|null}
   */
  static getStatusSheetCompanySystem() {
    return localStorage.getItem('status-sheet-company-system');
  }

  /**
   * @param {string|null} companySystem
   */
  static setStatusSheetCompanySystem(companySystem) {
    localStorage.setItem('status-sheet-company-system', companySystem);
  }

  /**
   * @return {string|null}
   */
  static getSelectionCategoriesCategoryType() {
    return localStorage.getItem('selection-categories-category-type');
  }

  /**
   * @param {string|null} categoryType
   */
  static setSelectionCategoriesCategoryType(categoryType) {
    localStorage.setItem('selection-categories-category-type', categoryType);
  }
};
