// Vue node modules
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import initValidation from '@/Client/Validation/Index';
import {createProvider} from './vue-apollo';
import PortalVue from 'portal-vue';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueLogger from 'vuejs-logger';
import VueRouter from 'vue-router';

// Internal config files
import App from './App.vue';
import axiosConfig from './Client/Config/Axios';
import router from './Client/Route/Route';

// Init the Vuex store using the config file
import store from '@/Client/Store/index';

// filters
import initFilters from '@/Client/Filter/Index';
import VueCurrencyFilter from 'vue-currency-filter';

// Common components
const GraphQLError = () => import('@/Client/Component/Common/Output/GraphQLError');
const IFEButtonTray = () => import('@/Client/Component/Common/Wrapper/IFEButtonTray');
const IFECard = () => import('@/Client/Component/Common/Wrapper/IFECard');
const IFECol = () => import('@/Client/Component/Common/Wrapper/IFECol');
const IFEContainer = () => import('@/Client/Component/Common/Wrapper/IFEContainer');
const IFEError = () => import('@/Client/Component/Common/Wrapper/IFEError');
const IFEForm = () => import('@/Client/Component/Common/Wrapper/IFEForm');
const IFEInputWrapper = () => import('@/Client/Component/Common/Wrapper/IFEInputWrapper');
const IFELoadingCard = () => import('@/Client/Component/Common/Output/IFELoadingCard');
const IFENotice = () => import('@/Client/Component/Common/Wrapper/IFENotice');
const IFERow = () => import('@/Client/Component/Common/Wrapper/IFERow');
const IFESimpleCheckbox = () => import('@/Client/Component/Common/Wrapper/IFESimpleCheckbox');
const IFESimpleInput = () => import('@/Client/Component/Common/Wrapper/IFESimpleInput');
const IFESimpleSelect = () => import('@/Client/Component/Common/Wrapper/IFESimpleSelect');
const IFESimpleTextarea = () => import('@/Client/Component/Common/Input/IFESimpleTextarea');
const IFEWarning = () => import('@/Client/Component/Common/Wrapper/IFEWarning');

// Mixins
import Mixin from '@/Client/Mixin/Index';

// Assets
import '@fontsource/metropolis';
import './assets/img/favicon.png';
import './assets/scss/custom.scss';

// Icons
const Active = () => import('@/Client/Component/Icon/Active');
const Add = () => import('@/Client/Component/Icon/Add');
const Close = () => import('@/Client/Component/Icon/Close');
const Delete = () => import('@/Client/Component/Icon/Delete');
const Edit = () => import('@/Client/Component/Icon/Edit');
const False = () => import('@/Client/Component/Icon/False');
const Grid = () => import('@/Client/Component/Icon/Grid');
const Help = () => import('@/Client/Component/Icon/Help');
const Inactive = () => import('@/Client/Component/Icon/Inactive');
const Info = () => import('@/Client/Component/Icon/Info');
const List = () => import('@/Client/Component/Icon/List');
const Locked = () => import('@/Client/Component/Icon/Locked');
const Logout = () => import('@/Client/Component/Icon/Logout');
const Next = () => import('@/Client/Component/Icon/Next');
const Note = () => import('@/Client/Component/Icon/Note');
const Notice = () => import('@/Client/Component/Icon/Notice');
const Order = () => import('@/Client/Component/Icon/Order');
const Previous = () => import('@/Client/Component/Icon/Previous');
const Soundtrack = () => import('@/Client/Component/Icon/Soundtrack');
const Source = () => import('@/Client/Component/Icon/Source.vue');
const Subtitle = () => import('@/Client/Component/Icon/Subtitle');
const Tick = () => import('@/Client/Component/Icon/Tick');
const True = () => import('@/Client/Component/Icon/True');
const Upload = () => import('@/Client/Component/Icon/Upload');
const User = () => import('@/Client/Component/Icon/User');

// Fonts
import '@fontsource/dm-sans';
import '@fontsource/lato';
import '@fontsource/metropolis';
import '@fontsource/noto-sans';
import '@fontsource/poppins';
import '@fontsource/roboto';
import '@fontsource/ubuntu';
import '@fontsource/varela';
import '@fontsource-variable/montserrat';
import '@fontsource-variable/open-sans';
import '@fontsource-variable/public-sans';

Vue.config.productionTip = false;

Vue.mixin(Mixin);

initValidation();

Vue.component('GqlError', GraphQLError);
Vue.component('IfeButtonTray', IFEButtonTray);
Vue.component('IfeCard', IFECard);
Vue.component('IfeCol', IFECol);
Vue.component('IfeContainer', IFEContainer);
Vue.component('IfeError', IFEError);
Vue.component('IfeForm', IFEForm);
Vue.component('IfeInputWrapper', IFEInputWrapper);
Vue.component('IfeLoadingCard', IFELoadingCard);
Vue.component('IfeNotice', IFENotice);
Vue.component('IfeRow', IFERow);
Vue.component('IfeSimpleCheckbox', IFESimpleCheckbox);
Vue.component('IfeSimpleInput', IFESimpleInput);
Vue.component('IfeSimpleSelect', IFESimpleSelect);
Vue.component('IfeSimpleTextarea', IFESimpleTextarea);
Vue.component('IfeSkeletonActorDirectorInput', ActorDirectorInput);
Vue.component('IfeSkeletonInput', FormInput);
Vue.component('IfeWarning', IFEWarning);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// Icons
Vue.component('IconActive', Active);
Vue.component('IconAdd', Add);
Vue.component('IconClose', Close);
Vue.component('IconDelete', Delete);
Vue.component('IconEdit', Edit);
Vue.component('IconFalse', False);
Vue.component('IconGrid', Grid);
Vue.component('IconHelp', Help);
Vue.component('IconInactive', Inactive);
Vue.component('IconInfo', Info);
Vue.component('IconList', List);
Vue.component('IconLocked', Locked);
Vue.component('IconLogout', Logout);
Vue.component('IconNext', Next);
Vue.component('IconNote', Note);
Vue.component('IconNotice', Notice);
Vue.component('IconOrder', Order);
Vue.component('IconPrevious', Previous);
Vue.component('IconSoundtrack', Soundtrack);
Vue.component('IconSource', Source);
Vue.component('IconSubtitle', Subtitle);
Vue.component('IconTick', Tick);
Vue.component('IconTrue', True);
Vue.component('IconUpload', Upload);
Vue.component('IconUser', User);

// Bootstrap stuff
// Bootstrap Vue components
Vue.use(BootstrapVue);
// Bootstrap icons
Vue.use(IconsPlugin);

// Vue stuff
// VueRouter - Single page router for Vue
Vue.use(VueRouter);
// VueAxios - XHR/Ajax
Vue.use(VueAxios, axiosConfig.coreApiInstance);
// Portal Vue
Vue.use(PortalVue);
// Auth axios instance (-/api)
Vue.prototype.$authApi = axiosConfig.authInstance;
// Alternative IMDB API axios instance
Vue.prototype.$imdbAltApi = axiosConfig.externalApiInstance;

Vue.use(VueCurrencyFilter, {
  symbol: '',
  thousandsSeparator: ',',
  fractionCount: 0,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false,
});

import Permissions from '@/Client/Mixin/Permissions';
import FormInput from '@/Client/Component/Common/Skeleton/FormInput';
import ActorDirectorInput from '@/Client/Component/Common/Skeleton/ActorDirectorInput';
import UserPreferences from '@/Client/Model/UserPreferences';

// **************************************************
// Handling the import custom styling for distributors
// **************************************************
const companyLogin = UserPreferences.getCompanyLogin();

if (companyLogin) {
  if (Object.hasOwn(companyLogin, 'cssClass')) {
    import(`./assets/scss/companies/${companyLogin.cssClass}.scss`);
  }
}

// Check whether a route requires auth before serving
router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some((record) => !record.meta.noAuth);
  const permsOnRoute = to.matched.some((record) => record.meta.permission);
  const routeName = to.name;

  if (!store.getters.isLoggedIn && 'login' === routeName) {
    UserPreferences.removeCompanyLogin();
  }

  if (store.getters.isLoggedIn
    && 'distributorLanding' !== routeName
    && Permissions.methods.hasPermission('ROLE_DISTRIBUTOR_PORTAL_USER', store.getters.user)
    && !Permissions.methods.hasPermission('ROLE_APP_USER', store.getters.user)) {
    const distributor = UserPreferences.getCompanyLogin();
    if (null === distributor) {
      store.dispatch('logout');
    }
  }

  if (authRequired) {
    store.dispatch('refreshToken').then(() => {
      if (Permissions.methods.hasPermission('ROLE_AIRLINE_CLIENT_PORTAL_USER', store.getters.user) &&
          !Permissions.methods.hasPermission('ROLE_NAVIGATION_USER', store.getters.user) &&
          (permsOnRoute || !permsOnRoute && routeName !== 'profile')) {
        next({name: 'airlineClientPortal'});
      }

      if (Permissions.methods.hasPermission('ROLE_DISTRIBUTOR_PORTAL_USER', store.getters.user) &&
          !Permissions.methods.hasPermission('ROLE_APP_USER', store.getters.user)) {
        const distributor = UserPreferences.getCompanyLogin();

        if (null === distributor) {
          store.dispatch('logout');
        }

        next(`/distributor/${distributor.id}/landing`);
      }
      if (!permsOnRoute || Permissions.methods.hasPermission(to.meta.permission, store.getters.user)) {
        next();
      }
    }).catch((error) => {
      if (error.response?.data?.code === 401) {
        store.dispatch('logout');
      }
    });
  }

  next();
});

// **************************************************
// Setting the page titles when route changes
// **************************************************

const APP_TITLE_SEPARATOR = ' :: ';
const APP_TITLE = 'IFE Data';

router.afterEach((to) => {
  Vue.nextTick(() => {
    let title = to.meta.title || '';

    if (!title) {
      if (Object.hasOwn(to.meta, 'fallbackTitle') && to.meta.fallbackTitle === false) {
        return;
      }

      // use the route name as a fallback for page title if it's not set in route
      // e.g. createSelection would end up as 'Create Selection'
      const split = to.name.replace(/([a-z0-9])([A-Z])/g, '$1 $2').split(' ');

      if (1 !== split.length) {
        if (['read'].includes(split[0].toLowerCase())) {
          split.shift();
        }
      }

      title = split.join(' ');
      title = title.charAt(0).toUpperCase() + title.slice(1);
    }

    document.title = (title ? title + APP_TITLE_SEPARATOR : '') + APP_TITLE;
  });
});

// **************************************************

const production = process.env.NODE_ENV === 'production';
const loggerOptions = {
  isEnabled: true,
  logLevel: production ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

Vue.use(VueLogger, loggerOptions);

initFilters();

// Vue instance
new Vue({
  store,
  router,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');
